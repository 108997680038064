<template>
  <div class="content-right">
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
      </div>
      <div class="content-body">
        <div class="body-content-overlay"></div>
        <!-- Main contact area -->
        <section class="chat-app-window">
          <!-- To load Conversation -->
          <div class="start-chat-area d-none">
            <div class="mb-1 start-chat-icon">
              <i data-feather="message-square"></i>
            </div>
            <h4 class="sidebar-toggle start-chat-text">Select Contact</h4>
          </div>
          <!--/ To load Conversation -->

          <!-- Active Contact -->
          <div class="active-chat">
            <!-- Contact Header -->
            <div class="chat-navbar">
              <header class="chat-header">
                <div class="d-flex align-items-center">
                  <div @click="$emit('sidebarActiveInactive')" class="sidebar-toggle d-block d-lg-none me-1">
                    <svg class="svg-inline--fa fa-bars" data-v-187c8d15="" aria-hidden="true" focusable="false"
                         data-prefix="fas" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 448 512" data-fa-i2svg="">
                      <path fill="currentColor"
                            d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"></path>
                    </svg>
                  </div>
                  <div class="avatar avatar-border user-profile-toggle m-0 me-1">
                    <img src="/app-assets/images/avatar.jpg" width="42" class="border-0" :alt="contactInfo.full_name"/>
                  </div>
                  <h6 class="mb-0">
                    {{ contactInfo.full_name }}<br>
                    <small v-for="profile_item in contactInfo.profile_items">
                      {{ profile_item.value }}<br>
                    </small>
                  </h6>
                </div>
                <div @click="$emit('handleEdit', contactInfo.contact.id)" class="d-flex align-items-center">
                  <h5 role="button">See profile</h5>
                </div>
              </header>
            </div>
            <!--/ Contact Header -->

            <!-- User Chat messages -->
            <div class="user-chats">
              <div class="chats">
                <ul class="nav nav-tabs">
                  <li v-for="tab in tabs" :key="tab.id" class="nav-item">
                    <a
                        :href="`#${tab.id}`"
                        :class="['nav-link', { active: activeTabId === tab.id }]"
                        @click="setActiveTab(tab)"
                    >
                      {{ tab.title }}
                    </a>
                  </li>
                </ul>
                <component :is="activeTab" :contactDetails="contactDetails"/>
              </div>
            </div>
            <!-- User Contact messages -->
          </div>
          <!--/ Active Contact -->
        </section>
        <!--/ Main contact area -->
      </div>
    </div>
  </div>
</template>

<script setup>
import Activities from "@/views/page/Contact/register/tabs/Activities.vue";
import Statements from "@/views/page/Contact/register/tabs/Statements.vue";
import Projects from "@/views/page/Contact/register/tabs/Projects.vue";
import Task from "@/views/page/Contact/register/tabs/Task.vue";
import Meeting from "@/views/page/Contact/register/tabs/Meeting.vue";
import Note from "@/views/page/Contact/register/tabs/Note.vue";
import More from "@/views/page/Contact/register/tabs/More.vue";
import {markRaw, ref} from "vue";

const activeTab = markRaw(Activities)
const activeTabId = ref('activities')
const props = defineProps({
  contactInfo: {
    type: Object,
    default: {
      full_name: '',
      profile_items: [],
      contact: {},
    }
  },
  contactDetails: {
    type: Array,
    default: []
  }
})

const tabs = [
  {id: 'activities', title: 'Activities', component: Activities},
  {id: 'statements', title: 'Statements', component: Statements},
  {id: 'projects', title: 'Projects', component: Projects},
  {id: 'task', title: 'Task', component: Task},
  {id: 'meeting', title: 'Meeting', component: Meeting},
  {id: 'note', title: 'Note', component: Note},
  {id: 'more', title: 'More', component: More}
]

const setActiveTab = tab => {
  activeTab.value = tab.component;
  activeTabId.value = tab.id;
};


</script>

<style scoped>
.user-chats {
  background-image: none !important;
  overflow-y: scroll;
  height: calc(100% - 65px);
  overflow-x: hidden;
}
</style>
